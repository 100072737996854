*{
    padding: 0;
    margin: 0;
}

.wrapper{
    background-image: url(../assets/image/ponpes.jpg);
    width: 100%;
    height: 100%;
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.wrapper::before{
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(12, 146, 163, 0.45);
}

.wrapper .logo-dashboard{
    height: 120px;
    width: 150px;
    justify-items: center;
    margin-top: 50px;
    margin-left: 45%;
    margin-bottom: 20px;
    filter: brightness(100%);
}

.wrapper button{
    cursor: pointer;
    justify-content: center;
    margin-left: 42%;
}

.wrapperSDIT{
    background-image: url(../assets/image/SDIT1.JPG);
    width: 100%;
    height: 100%;
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.wrapperSDIT::before{
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(26, 26, 27, 0.734);
}

.wrapperSDIT .logo-dashboard{
    height: 120px;
    width: 150px;
    justify-items: center;
    margin-top: 50px;
    margin-left: 45%;
    margin-bottom: 20px;
    filter: brightness(100%);
}

.wrapperSDIT button{
    cursor: pointer;
    justify-content: center;
    margin-left: 42%;
}

.wrapperPAUD1{
    background-image: url(../assets/image/PAUD1.jpg);
    width: 100%;
    height: 100%;
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.wrapperPAUD1::before{
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(26, 26, 27, 0.734);
}

.wrapperPAUD1 .logo-dashboard{
    height: 120px;
    width: 150px;
    justify-items: center;
    margin-top: 50px;
    margin-left: 45%;
    margin-bottom: 20px;
    filter: brightness(100%);
}

.wrapperPAUD1 button{
    cursor: pointer;
    justify-content: center;
    margin-left: 42%;
}

.wrapperPonpes{
    background-image: url(../assets/image/ponpes1.JPG);
    width: 100%;
    height: 100%;
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-top:0px ;
    margin-top: 0px;
}

.wrapperPonpes::before{
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(26, 26, 27, 0.734);
    padding-top:0px ;
    margin-top: 0px;
}

.wrapperPonpes .logo-dashboard{
    height: 120px;
    width: 150px;
    justify-items: center;
    margin-top: 50px;
    margin-left: 45%;
    margin-bottom: 20px;
    filter: brightness(100%);
}

.wrapperPonpes button{
    cursor: pointer;
    justify-content: center;
    margin-left: 42%;
}

.wrapperSDIT2{
    background-image: url(../assets/image/SDIT2.jpeg);
    width: 100%;
    height: 100%;
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-top:0px ;
    margin-top: 0px;
}

.wrapperSDIT2::before{
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(26, 26, 27, 0.734);
    padding-top:0px ;
    margin-top: 0px;
}

.wrapperSDIT2 .logo-dashboard{
    height: 120px;
    width: 150px;
    justify-items: center;
    margin-top: 50px;
    margin-left: 45%;
    margin-bottom: 20px;
    filter: brightness(100%);
}

.wrapperSDIT2 button{
    cursor: pointer;
    justify-content: center;
    margin-left: 42%;
}

.wrapperSMAIT{
    background-image: url(../assets/image/SMAIT.jpeg);
    width: 100%;
    height: 100%;
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-top:0px ;
    margin-top: 0px;
}

.wrapperSMAIT::before{
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(26, 26, 27, 0.734);
    padding-top:0px ;
    margin-top: 0px;
}

.wrapperSMAIT .logo-dashboard{
    height: 120px;
    width: 150px;
    justify-items: center;
    margin-top: 50px;
    margin-left: 45%;
    margin-bottom: 20px;
    filter: brightness(100%);
}

.wrapperSMAIT button{
    cursor: pointer;
    justify-content: center;
    margin-left: 42%;
}

.wrapperSMAIT{
    background-image: url(../assets/image/PAUDIT2.jpeg);
    width: 100%;
    height: 100%;
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-top:0px ;
    margin-top: 0px;
}

.wrapperSMAIT::before{
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(26, 26, 27, 0.734);
    padding-top:0px ;
    margin-top: 0px;
}

.wrapperSMAIT .logo-dashboard{
    height: 120px;
    width: 150px;
    justify-items: center;
    margin-top: 50px;
    margin-left: 45%;
    margin-bottom: 20px;
    filter: brightness(100%);
}

.wrapperSMAIT button{
    cursor: pointer;
    justify-content: center;
    margin-left: 42%;
}

.wrapperPAUD2{
    background-image: url(../assets/image/PAUDIT2.jpeg);
    width: 100%;
    height: 100%;
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding-top:0px ;
    margin-top: 0px;
}

.wrapperPAUD2::before{
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(26, 26, 27, 0.734);
    padding-top:0px ;
    margin-top: 0px;
}

.wrapperPAUD2 .logo-dashboard{
    height: 120px;
    width: 150px;
    justify-items: center;
    margin-top: 50px;
    margin-left: 45%;
    margin-bottom: 20px;
    filter: brightness(100%);
}

.wrapperPAUD2 button{
    cursor: pointer;
    justify-content: center;
    margin-left: 42%;
}


.btnDashboard{
    margin-bottom: 10px;
    position: relative;
    width: 250px;
    border-radius: 20px;
    height: 50px;
    text-size-adjust: 20px;
    font-size: 15px;
    background-color: #5cc6d0;
}


@media screen and (max-width: 400px) {

    .wrapper{
        background-image: url(../assets/image/ponpes.jpg);
        width: 100%;
        height: 100%;
        background-size: cover;
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .wrapper .logo-dashboard{
        height: 70px;
        width: 90px;
        justify-items: center;
        margin-top: 20px;
        margin-bottom: 10px;
        margin-left: 40%;
        filter: brightness(100%);
    }
    
    .wrapper button{
        cursor: pointer;
        justify-content: center;
        margin-left: 30%;
    }
    
    .btnDashboard{
        margin-top: 5px;
        position: relative;
        width: 170px;
        border-radius: 10px;
        height: 25px;
        text-size-adjust: 20px;
        font-size: 10px;
        background-color: #5cc6d0;
        margin-bottom: 5px
    }
    
    .wrapperSDIT{
        background-image: url(../assets/image/SDIT1.JPG);
        width: 100%;
        height: 100%;
        background-size: cover;
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .wrapperSDIT .logo-dashboard{
        height: 70px;
        width: 90px;
        justify-items: center;
        margin-top: 20px;
        margin-bottom: 10px;
        margin-left: 40%;
        filter: brightness(100%);
    }
    
    .wrapperSDIT button{
        cursor: pointer;
        justify-content: center;
        margin-left: 30%;
    }
    
  
    .wrapperPAUD1{
        background-image: url(../assets/image/PAUD1.jpg);
        width: 100%;
        height: 100%;
        background-size: cover;
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .wrapperPAUD1 .logo-dashboard{
        height: 70px;
        width: 90px;
        justify-items: center;
        margin-top: 20px;
        margin-bottom: 10px;
        margin-left: 40%;
        filter: brightness(100%);
    }
    
    .wrapperPAUD1 button{
        cursor: pointer;
        justify-content: center;
        margin-left: 30%;
    }
    
    .wrapperPonpes{
        background-image: url(../assets/image/ponpes1.JPG);
        width: 100%;
        height: 100%;
        background-size: cover;
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .wrapperPonpes .logo-dashboard{
        height: 70px;
        width: 90px;
        justify-items: center;
        margin-top: 20px;
        margin-bottom: 10px;
        margin-left: 40%;
        filter: brightness(100%);
    }
    
    .wrapperPonpes button{
        cursor: pointer;
        justify-content: center;
        margin-left: 30%;
    }

    .wrapperSDIT2{
        background-image: url(../assets/image/ponpes1.JPG);
        width: 100%;
        height: 100%;
        background-size: cover;
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .wrapperSDIT2 .logo-dashboard{
        height: 70px;
        width: 90px;
        justify-items: center;
        margin-top: 20px;
        margin-bottom: 10px;
        margin-left: 40%;
        filter: brightness(100%);
    }
    
    .wrapperSDIT2 button{
        cursor: pointer;
        justify-content: center;
        margin-left: 30%;
    }

    .wrapperSMAIT{
        background-image: url(../assets/image/SMAIT.jpeg);
        width: 100%;
        height: 100%;
        background-size: cover;
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .wrapperSMAIT .logo-dashboard{
        height: 70px;
        width: 90px;
        justify-items: center;
        margin-top: 20px;
        margin-bottom: 10px;
        margin-left: 40%;
        filter: brightness(100%);
    }
    
    .wrapperSMAIT button{
        cursor: pointer;
        justify-content: center;
        margin-left: 30%;
    }

    .wrapperPAUD2{
        background-image: url(../assets/image/PAUDIT2.jpeg);
        width: 100%;
        height: 100%;
        background-size: cover;
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .wrapperPAUD2 .logo-dashboard{
        height: 70px;
        width: 90px;
        justify-items: center;
        margin-top: 20px;
        margin-bottom: 10px;
        margin-left: 40%;
        filter: brightness(100%);
    }
    
    .wrapperPAUD2 button{
        cursor: pointer;
        justify-content: center;
        margin-left: 30%;
    }
}