.dashboardtablehead{
  font-weight: bold;
}

.search{
  width: 50px;
}

.jenis-pegawai{
  padding-left: 0px;
  margin-left: 0px;
}


.label-jenis{
  margin-left: 0px;
}

.Scroll{
  height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
}


.download-table-xls-button {
  background-color: red;
  margin-left: 50px;
  margin-bottom: 30px;
  border-radius: 10px;
  width: 150px;
  height: 35px;
}

.search {
  margin-left: 5px;
  margin-bottom: 30px;
  border-radius: 5px;
  width: 300px;
  height: 35px;
}

.button is-primary mb-2 {
  margin-top: 10px;
}

.table__wrapper {
  display: block;
  max-width: -moz-fit-content;
  //max-width: fit-content;
  margin: 0 auto;
  overflow-x: auto;
  white-space: nowrap;
  margin-left: 10px;
  margin-right: 10px;
  // height: 100px;
  overflow: auto;
}

.table__wrapper_Scroll_Log{
  display: block;
  max-width: -moz-fit-content;
  //max-width: fit-content;
  margin: 0 auto;
  overflow-x: auto;
  white-space: nowrap;
  margin-left: 10px;
  margin-right: 10px;
  height: 600px;
  overflow: auto;
}

.label-unit {
  margin-left: 50px;
}
