.card-container {
    width: 500px;
    overflow: hidden;
    box-shadow: 0px 0px 15px -5px;
    margin-left: 38%;
    margin-top: 10%;
    border-radius: 20px;
    padding: 10px;
  }
  
  .judul {
    font-size: 30px;
    text-align: center;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    color: red;
  }

  li{
    font-size: 15px;
  }
  
  .a {
    margin-top: 50px;
    list-style-type: square;
    margin-left: 30px;
  }
  
  .btnPengumuman {
    margin-top: 50px;
    background-color: aqua;
    width: 180px;
    height: 30px;
    font-weight: bold;
    margin-left: 33%;
    border-radius: 10px;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  @media screen and (max-width: 600px) {
    .card-container {
      width: 300px;
      overflow: hidden;
      box-shadow: 0px 0px 15px -5px;
      margin-left: 10%;
      margin-top: 20%;
      border-radius: 20px;
      padding: 10px;
    }
  
    .judul {
      font-size: 25px;
      text-align: center;
      font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      font-weight: bold;
      color: red;
    }

    
  li{
    font-size: 15px;
  }
  
    .a {
      margin-top: 50px;
      list-style-type: square;
      margin-left: 30px;
    }
  
    .btnPengumuman {
      margin-top: 50px;
      background-color: aqua;
      width: 180px;
      height: 30px;
      font-weight: bold;
      margin-left: 20%;
      border-radius: 10px;
      align-items: center;
      border-radius: 10px;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
  