.body{
    // margin: 20px;
    padding: 0;
}

.simpeg{
    text-align: center;
    font-weight: bold;
    margin-left: 10px;
}

.Logo{
    width: 30px;
    height: 40px;
}

.main-page-login{
    display: flex;
    box-sizing: border-box;
    margin :0px;
    
    .left{
        background-color: gray;
        flex: 300px;
        width: 1300px;
        // height: 80%;
        .bg-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
    }
    .right{
        background-color:lightblue;
        width: 500px;
        height: auto;
        padding-top: 300px;
        padding-left: 25px ;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        .box{
            width: 450px;
            height: auto;

        }
        .title{
                margin-top: 30px;
                font-style: normal;
                font-weight: bold;
                font-size: 32px;
                text-align: center;
            
        }     
    }
}

.navbar-brand{
    height: 60px;
}


@media screen and (max-width: 500px) {
    .main-page-login{
        display: block;
        box-sizing: border-box;
        .left{
            background-color: gray;
            // flex: 300px;
            width: 100%;
            height: 100%;
            .bg-image {
                width: 100%;
                height: 100%;
   
              }
        }
        .right{
            background-color:lightblue;
            width: 100%;
            height: auto;
            padding-top: 100px;
            padding-bottom: 50px ;
  
            flex-direction: column;
            box-sizing: border-box;
            .box{
                width: 350px;
                height: auto;
    
            }
            .title{
                    margin-top: 30px;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 32px;
                    text-align: center;
                
            }     
        }
    }
     
}
